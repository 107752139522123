import {Injectable, ModuleWithProviders, NgModule} from '@angular/core';


@NgModule({})
export class VitaAppSharedModule {
  static forRoot(): ModuleWithProviders<VitaAppSharedModule> {
    return {
      ngModule: VitaAppSharedModule,
      providers: [VitaAppService]
    };
  }
}

@Injectable({
  providedIn: 'root',
})
export class VitaAppService {

  openToken: string;
  userId: string;
  providerId: string;
  scopes: string[];
  idToken: String;

  providerToken: string;

  constructor() { }

  setProviderId(providerId: string){
    this.providerId = providerId
  }

  getProviderId(): string{
    return this.providerId;
  }

  setProviderToken(providerToken: string){
    this.providerToken = providerToken;
  }

  getProviderToken(): string{
    return this.providerToken;
  }

  setOpenToken(opentoken){
    this.openToken = opentoken;
  }

  getOpenToken(){
    return this.openToken;
  }

  setUserId(userId: string){
    this.userId = userId;
  }

  getUserId(): string{
    return this.userId
  }

  setScopes(scopes: string[]){
    this.scopes = scopes;
  }

  getScopes(): string[]{
    return this.scopes;
  }


  setIdToken(idToken: string){
    this.idToken = idToken;
  }

  getIdToken(): String{
    return this.idToken;
  }
}
